import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Form, InputGroup, Modal, Table, ToastContainer, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { apiServices } from "../../../apiServices/ApiServices";
import withReactContent from "sweetalert2-react-content";
import { Swal } from "sweetalert2/dist/sweetalert2";
import { toast } from "react-toastify";
import { dialog, swalErro, swalSucesso, toastSucesso } from "../../Swal";
import { NailingContext } from "../../../Context/NailingContext/Context";
import { apenasNumeros, formatarMoedaReal, formatarParaMascaraMoeda, limpaMascaraMoeda, realMoeda } from "../../../functions/Mask";
import { FaCheck } from "react-icons/fa6";
import { UserContext } from "../../../Context/UserContext";
import { userServices } from "../../../apiServices/userServices";
import { useNota } from "../../../Context/NotaContext/NotaContext";
import imgPdv from "../../../assets/svg/imagePdv.svg";
import { AsyncTypeahead, Input, Typeahead } from "react-bootstrap-typeahead";
import { productServices } from "../../../apiServices/productServices";
import { useTabelaMontagem } from "../../../Context/TabelaMontagemContext/TabelaMontagemContext";
import { m } from "framer-motion";
import AsyncSelect from "react-select/async";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import { setOptions } from "leaflet";

export const GeracaoNota = () => {
	const token = localStorage.getItem("token");
	const [checked, setChecked] = useState("0");

	const [orderNailing, setOrderNailing] = useState([]);
	const [show, setShow] = useState(false);
	const inputRef = useRef(null);
	const [pedidos, setPedidos] = useState([]);
	const [value, setValue] = useState("");
	const [codigoPedidos, setCodigoPedidos] = useState([]);
	const [selectedItems, setSelectedItems] = useState([]);
	const { listUser, getClienteData, clientData, setClientData } = useContext(UserContext);
	const { listarTabelaMontagem, listaTabelaMontagem } = useTabelaMontagem();
	const [materialColor, setMaterialColor] = useState([]);

	const handleGetMaterialColor = async () => {
		const responseMaterialColor = await productServices.selectListMaterialcolor("", token);
		setMaterialColor(responseMaterialColor.data);
	};

	useEffect(() => {
		handleGetMaterialColor();
	}, []);

	useEffect(() => {
		if (show) {
			listarTabelaMontagem();
		}
	}, [show]);
	let timerId = null;
	const handleModalEntered = () => {
		inputRef.current && inputRef.current.focus();
	};

	const { calcularNotas, calculoNotas, calcularPedido, calculoPedido, setCalculoPedido, setCalculoNotas, verificaProdutoProduzido, listConfiguracoes, configuracoes } = useNota();

	const [accumulatedOrderWeight, setAccumulatedOrderWeight] = useState({
		ag: 0,
		au: 0,
	});

	const handleSetAcumulatedOrderWeight = () => {
		const acumulado = pedidos?.reduce(
			(acc, curr) => {
				if (curr.MATERIAL_CODE == "AU") {
					acc.au += curr.ACABAMENTO_FINAL_WEIGHT ? curr.ACABAMENTO_FINAL_WEIGHT : curr.FINAL_WEIGHT;
				} else if (curr.MATERIAL_CODE == "AG") {
					acc.ag += curr.ACABAMENTO_FINAL_WEIGHT ? curr.ACABAMENTO_FINAL_WEIGHT : curr.FINAL_WEIGHT;
				}
				return acc;
			},
			{
				ag: 0,
				au: 0,
			}
		);

		setAccumulatedOrderWeight(acumulado);
	};

	useEffect(() => {
		handleSetAcumulatedOrderWeight();
	}, [pedidos]);

	const handleGetCode = e => {
		toast.dismiss();
		const valor = e.target.value;
		setValue(valor);

		if (timerId) {
			clearTimeout(timerId);
		}

		timerId = setTimeout(() => {
			handleGetOrder(token, valor);
		}, 1000);
	};

	const [clientSelected, setClientSelected] = useState("");
	const handleSetClient = e => {
		setClientSelected(e.target.value);
	};
	const [transactionClientData, setTransactionClientData] = useState([]);
	const handleGetClientData = async () => {
		const response = await userServices.getClientLastTransaction({
			client_id: clientSelected,
			token: localStorage.getItem("token"),
		});
		setTransactionClientData(response.data.transactions);
	};

	useEffect(() => {
		handleGetClientData();
	}, [clientSelected]);

	const [clientList, setClientList] = useState([]);
	const handleGetUser = async () => {
		const response = await listUser({
			token: token,
			page: "client",
			limit: 9999,
			permission_type: 1,
		});
		setClientList(response.data.users);
	};

	const [pedidoAtual, setPedidoAtual] = useState({});

	const pesoPolimento = pedidoAtual?.POLIMENTO_WEIGHT ? JSON.parse(pedidoAtual.POLIMENTO_WEIGHT).reduce((acc, curr) => acc + Number(curr.weight), 0) : 0;
	const pesoAdicional = pedidoAtual?.ADITIONAL_WEIGHT ? JSON.parse(pedidoAtual.ADITIONAL_WEIGHT).reduce((acc, curr) => acc + Number(curr.weight), 0) : 0;
	useEffect(() => {
		console.log(pesoAdicional);
	}, [pesoAdicional]);
	const typeaheadRef = useRef(null);

	const handleCalcularPedido = async () => {
		const payloadCalculo = {
			pedidos: [pedidoAtual.ID],
			adicionais: [valoresAdicionais],
			montagem: [montagem],
			corrente: [corrente],
			config_data: [configData],
			rodio: valoresRodioAdicionais,
			token: token,
			cobra_ouro: false,
			cobra_prata: false,
			id_config: selectedTabela,
			total_frete: 0,
			fechamento_nota: false,
		};

		if (pedidoAtual.ID) {
			const response = await calcularPedido(payloadCalculo);
		}
	};

	const handleAddPedidoAtualEmPedidos = () => {
		if (pedidoAtual) {
			setPedidos(prevPedidos => [...prevPedidos, pedidoAtual]);
			setCodigoPedidos(prevPedidos => [...prevPedidos, pedidoAtual?.ID]);
			setPayload({
				...payload,
				adicionais: [...(payload.adicionais || []), valoresAdicionais],
				rodio: [...(payload.rodio || []), valoresRodioAdicionais],
				corrente: [...(payload.corrente || []), corrente],
				montagem: [...(payload.montagem || []), montagem],
				config_data: [...(payload.config_data || []), configData],
			});
			setSelectedTab("dados");
			setPedidoAtual({});
			setCalculoPedido({});

			setValoresAdicionais({
				id_order: "",
				valores: [],
			});

			setMontagem({
				id_order: "",
				montagem: {
					id_montagem: "",
					valor_montagem: "",
				},
			});

			setConfigData({
				id_order: "",
				id_config: "",
			});

			setCorrente({
				id_order: "",
				corrente: [],
			});
		}
	};

	const handleGetOrder = async (token, order) => {
		let response;

		if (order.length === 10) {
			toast.loading("Processando...");
			response = await apiServices.getOrder(token, 1, 1, order, null, null, true, false, null, null, true);
		}

		if (response.data.orders.length !== 0) {
			const existe = pedidos.find(item => item.CODE === response.data.orders[0].CODE);

			const permite = true;

			const mesmoCliente = pedidos.length > 0 ? pedidos.find(item => item.ID_CLIENTE === response?.data.orders[0].ID_CLIENTE) : true;

			if (!existe && mesmoCliente) {
				setPedidoAtual(response.data.orders[0]);
				setPayload({ ...payload, order: [...payload.order, response.data.orders[0].ID] });
				if (response.data.nailing?.length > 0) {
					setOrderNailing(prevNailing => [...prevNailing, ...response.data.nailing]);
				}

				await getClienteData({ user_id: response.data.orders[0].ID_CLIENTE });
				// await calcularPedido({ pedidos: [response.data.orders[0].ID], cobra_ouro: false, cobra_prata: false });

				toast.dismiss();
				// toastSucesso("Pedido adicionado com sucesso!");
				setValue("");
				handleModalEntered();
			} else if (!mesmoCliente) {
				toast.dismiss();
				swalErro("Pedido de cliente diferente do cliente selecionado.");
				setValue("");
				handleModalEntered();
			} else {
				toast.dismiss();
				swalErro("Este pedido já foi adicionado.");
				setValue("");
				handleModalEntered();
			}
		} else {
			toast.dismiss();
			swalErro("Nenhum pedido encontrato com este código. Para mais informações, entre em contato com o nosso setor de suporte!");
			setValue("");
			handleModalEntered();
		}
	};

	const removeOrder = orderCode => {
		const index = pedidos.findIndex(pedido => pedido.CODE === orderCode);

		if (index > -1) {
			const updatedPedidos = [...pedidos];
			const pedidoRemovido = updatedPedidos.splice(index, 1);

			const updatedCodigo = [...updatedPedidos.map(pedido => pedido.ID)];
			setPedidos(updatedPedidos);
			setCodigoPedidos(updatedCodigo);
			setPayload({
				...payload,
				order: payload.order.filter(item => item != pedidoRemovido[0].ID),
				adicionais: payload.adicionais.filter(item => item.id_order != pedidoRemovido[0].ID),
				montagem: payload.montagem.filter(item => item.id_order != pedidoRemovido[0].ID),
				corrente: payload.corrente.filter(item => item.id_order != pedidoRemovido[0].ID),
				config_data: payload.config_data.filter(item => item.id_order != pedidoRemovido[0].ID),
			});
			toastSucesso("Pedido removido com sucesso!");
		}

		const updatedNailing = orderNailing.filter(pedido => pedido.order_code !== orderCode);
		setOrderNailing(updatedNailing);

		const updatedSelectedItems = selectedItems.filter(pedido => pedido.order_code !== orderCode);

		setSelectedItems(updatedSelectedItems);
	};

	const handleSave = async () => {
		const confirm = await dialog("Deseja realmente gerar a nota?");

		if (confirm.isConfirmed) {
			handleUpdateStatus(pedidos);
		}
	};

	const [configData, setConfigData] = useState({
		id_order: "",
		id_config: "",
	});

	useEffect(() => {
		if (pedidoAtual.ID) {
			setConfigData(prevConfigData => ({ ...prevConfigData, id_order: pedidoAtual.ID }));
		}
	}, [pedidoAtual]);

	const [selectedTabela, setSelectedTabela] = useState(1);

	const [payload, setPayload] = useState({
		order: [],
		adicionais: [],
		montagem: [],
		corrente: [],
		config_data: [],
		rodio: [],
		token: token,
		status: 25,
		operation_type: 1,
		cobra_ouro: false,
		cobra_prata: false,
		total_acrecimos: 0,
		total_frete: 0,
		fechamento_nota: true,
		description: `Geração de nota`,
	});
	useEffect(() => {
		console.log(payload);
	}, [payload]);
	// useEffect(() => {
	// 	const id_orders = pedidos.map(item => item.ID);
	// 	setPayload({ ...payload, order: id_orders });
	// }, [pedidos]);

	const handleUpdateStatus = async orders => {
		toast.dismiss();

		try {
			if (orders.length > 0) {
				const response = await apiServices.updateStatusOrder(payload);
				if (response.data.status === "success") {
					toast.success(response.data.msg, {
						position: toast.POSITION.BOTTOM_CENTER,
					});
					setTimeout(() => {
						setPedidos([]);
						setValue([]);
						setCodigoPedidos([]);
						window.location.reload();
					}, 3000);
				} else {
					toast.error(response.data.msg, {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				}
			}
		} catch (error) {
			console.error("Erro ao atualizar o status:", error);
		}
	};

	const [totalNailing, setTotalNailing] = useState(0);
	const handleSetTotalNailingValue = () => {
		const valor = calculoNotas?.itens?.map(item => {
			// Inicializa o total para o item atual
			let valorRetorno = 0;

			// Itera sobre os valores de cravação
			item?.nailing_values?.forEach(nailing => {
				// Converte para número e soma, assegurando que os valores são válidos
				const pave = parseFloat(nailing?.pave) || 0;
				const grifas = parseFloat(nailing?.grifas) || 0;
				const central = parseFloat(nailing?.central) || 0;
				const inglesa = parseFloat(nailing?.inglesa) || 0;
				const cravacao = parseFloat(nailing?.cravacao) || 0;

				// Soma os valores
				valorRetorno += pave + grifas + central + inglesa + cravacao;
			});

			// Retorna o valor total acumulado para o item atual
			return valorRetorno;
		});

		setTotalNailing(valor?.reduce((a, b) => a + b, 0)); // Aqui 'valor' será um array dos totais para cada item
	};

	const [totalMaoDeObraValue, setTotalMaoDeObraValue] = useState(0);
	const handleSetMaoDeObraValues = () => {
		const valor = calculoNotas?.itens?.reduce((acc, curr) => {
			return acc + parseFloat(curr.valor_total_de_mao_de_obra || 0);
		}, 0);

		setTotalMaoDeObraValue(valor);
	};

	const [totalMaterialValue, setTotalMaterialValue] = useState(0);
	const handleSetTotalMaterialValue = () => {
		const valor = calculoNotas?.itens?.reduce((acc, curr) => {
			return acc + parseFloat(curr.valor_de_material_do_pedido || 0);
		}, 0);

		setTotalMaterialValue(valor);
	};

	useEffect(() => {
		handleSetTotalNailingValue();
		handleSetMaoDeObraValues();
		handleSetTotalMaterialValue();
	}, [calculoNotas]);

	const [tab, setSelectedTab] = useState("dados");

	const [valoresAdicionais, setValoresAdicionais] = useState({
		id_order: "",
		valores: [],
	});

	const [valoresRodioAdicionais, setValoresRodioAdicionais] = useState({
		id_order: "",
		valores: [],
	});

	useEffect(() => {
		if (pedidoAtual.ID) {
			setValoresAdicionais({
				...valoresAdicionais,
				id_order: pedidoAtual.ID,
			});

			setValoresRodioAdicionais({
				...valoresRodioAdicionais,
				id_order: pedidoAtual.ID,
			});

			setMontagem({
				...montagem,
				id_order: pedidoAtual.ID,
			});

			setCorrente({
				...corrente,
				id_order: pedidoAtual.ID,
			});
		}
	}, [pedidoAtual]);

	const [isCorrenteFetching, setIsCorrenteFetching] = useState(false);
	const [correnteList, setCorrenteList] = useState([]);
	const [correnteSelecionada, setCorrenteSelecionada] = useState({});

	const [corrente, setCorrente] = useState({
		id_order: "",
		corrente: [],
	});

	const handleChangeQuantidade = (id, valor) => {
		console.log(id, valor);

		// Atualiza o estado corrente com as novas quantidades
		setCorrente(prevState => ({
			...prevState,
			corrente: prevState.corrente.map(item => (item.value === id ? { ...item, quantidade: valor } : item)),
		}));
	};

	const [options, setOtions] = useState([]);

	useEffect(() => {
		setOtions(
			correnteList?.map(option => ({
				value: option.ID,
				id_corrente: option.ID,
				peso: option.WEIGHT,
				quantidade: 1,
				label: (
					<div className="d-flex flex-row gap-3 align-items-center">
						<img
							alt={option.DESCRIPTION}
							src={option.FILE}
							style={{
								height: "45px",
								marginRight: "10px",
								width: "45px",
							}}
						/>
						<h6>{`${option.NAME} - ${option.DESCRIPTION}`}</h6>
					</div>
				),
			}))
		);
	}, [correnteList]);

	useEffect(() => {
		console.log(corrente);
	}, [corrente]);

	const handleGetCorrentes = async inputValue => {
		if (inputValue.length < 3) {
			return []; // Retorna um array vazio se o comprimento da consulta for menor que 3
		}
		const token = localStorage.getItem("token");
		setIsCorrenteFetching(true);
		try {
			const response = await productServices.getProducts(token, 1, inputValue, "", "", "8");
			if (response.data.products) {
				return response.data.products.map(option => ({
					value: option.ID,
					nome: option.NAME,
					quantidade: 1,
					peso: option.WEIGHT,
					label: (
						<div className="d-flex flex-row gap-3 align-items-center">
							<img
								alt={option.DESCRIPTION}
								src={option.FILE}
								style={{
									height: "40px",
									marginRight: "10px",
									width: "40px",
								}}
							/>
							<h6>{`${option.NAME}`}</h6>
						</div>
					),
				}));
			}
		} catch (error) {
			console.error("Error fetching products:", error);
		} finally {
			setIsCorrenteFetching(false);
		}
		return []; // Retorna um array vazio em caso de erro ou se não houver produtos
	};

	const loadOptions = inputValue => {
		return handleGetCorrentes(inputValue); // Chama a função assíncrona diretamente
	};

	const handleSelection = selectedOptions => {
		setCorrente(prevState => ({
			...prevState,
			corrente: selectedOptions || [], // Atualiza corrente com as opções selecionadas
		}));
	};

	const [montagem, setMontagem] = useState({
		id_order: "",
		montagem: {
			id_montagem: "",
			valor_montagem: "",
		},
	});

	useEffect(() => {
		console.log(montagem);
	}, [montagem]);

	const handleSelectTabela = e => {
		const valorEvento = e.target.value;
		const valorTabela = valorEvento.split("_")[1];
		const idMontagem = valorEvento.split("_")[0];

		setMontagem({
			...montagem,
			montagem: {
				valor_montagem: valorTabela,
				id_montagem: idMontagem,
			},
		});
	};

	useEffect(() => {
		handleCalcularPedido();
	}, [valoresAdicionais.valores, valoresRodioAdicionais.valores, configData, montagem, corrente]);

	useEffect(() => {
		calcularNotas(payload);
	}, [pedidos, checked]);

	const handleCloseModal = () => {
		setShow(false);
		setClientSelected("");
		setClientData({});
		setPedidoAtual({});
		setPedidos([]);
		setCalculoPedido([]);
		setCalculoNotas([]);
		setPayload({
			order: [],
			adicionais: [],
			montagem: [],
			corrente: [],
			config_data: [],
			token: token,
			status: 25,
			operation_type: 1,
			cobra_ouro: false,
			cobra_prata: false,
			id_config: selectedTabela,
			total_acrecimos: 0,
			total_frete: 0,
			fechamento_nota: true,
			description: `Geração de nota`,
		});
	};
	return (
		<>
			<ToastContainer />
			<Button
				onClick={() => {
					handleGetUser();
					setShow(true);
					listConfiguracoes();
				}}
			>
				Iniciar Faturamento
			</Button>
			<Modal fullscreen show={show} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Faturamento</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex flex-row p-0">
					<div className="border border-right w-100 p-3">
						<ToggleButtonGroup type="checkbox" className="mb-3 d-flex flex-row justify-content-between">
							<ToggleButton
								onClick={() => setSelectedTab("dados")}
								variant={tab == "dados" ? "primary" : "info"}
								className="w-100"
								style={{
									clipPath: "polygon(0% 0%, calc(100% - 8px) 0%, 100% 50%, calc(100% - 8px) 100%, 0% 100%)",
									backgroundColor: tab == "dados" ? "#506FD9" : "#CCD5E0",
									border: tab == "dados" ? "1px solid #506FD9" : "none",
								}}
							>
								Dados
							</ToggleButton>
							<ToggleButton
								variant={tab == "detalhes" ? "primary" : "info"}
								onClick={() => setSelectedTab("detalhes")}
								className="w-100"
								style={{
									clipPath: "polygon(100% 0px, 100% 50%, 100% 100%, 0% 100%, 8px 50%, 0% 0%)",
									backgroundColor: tab == "detalhes" ? "#506FD9" : "#CCD5E0",
									border: tab == "detalhes" ? "1px solid #506FD9" : "none",
								}}
							>
								Detalhes
							</ToggleButton>
						</ToggleButtonGroup>
						<div
							className={tab == "dados" ? "d-block" : "d-none"}
							style={{
								overflowX: "hidden",
							}}
						>
							<div className="">
								<>
									<Form.Control
										style={{
											fontSize: "20px",
											fontWeight: "bold",
										}}
										type="text"
										placeholder={"Digite o código do pedido"}
										className="display-3 h-20 z-3 w-100 text-center"
										ref={inputRef}
										autoFocus={true}
										id="code-form"
										value={value}
										onChange={handleGetCode}
									/>
								</>
							</div>
							<Card className="mt-3 card-one">
								<Card.Body>
									<Form>
										<Form.Label as="h5">
											<strong>Selecione a tabela</strong>
										</Form.Label>
										<Form.Select onChange={e => setConfigData({ ...configData, id_config: e.target.value })}>
											<option value="">-- Selecione uma tabela --</option>
											{configuracoes?.map((config, index) => (
												<option key={config.id} value={config.id}>
													{config.config_name}
												</option>
											))}
										</Form.Select>
									</Form>
								</Card.Body>
							</Card>
							<Card className="mt-3">
								<Card.Body className="d-flex flex-row justify-content-between">
									<div className="w-100 h-100">
										<h2 className=" w-100">
											<strong>{clientData.FANTASY_NAME}</strong>
										</h2>
										<div className="d-flex flex-column justify-content-between">
											<h4 className=" w-100">
												<strong>Cliente: </strong>
												{clientData.NAME}
											</h4>
											<h4 className=" w-100">
												<strong>CNPJ: </strong>
												{clientData.DOCUMENT}
											</h4>
										</div>
									</div>
									<div className="w-100 d-flex flex-column align-items-end">
										{pedidoAtual?.CODE ? <h2 className=" fw-bold">Pedido: {pedidoAtual?.CODE}</h2> : null}
										{pedidoAtual?.PART_CODE && <h4 className="text-end">Peça: {pedidoAtual?.PART_CODE}</h4>}
										{pedidoAtual?.PRODUCT_SHORT_DESCRIPTION && <h4 className="text-end">{pedidoAtual?.PRODUCT_SHORT_DESCRIPTION}</h4>}
									</div>
								</Card.Body>
							</Card>
							<Card className="border-0 mt-3">
								<Card.Body>
									<div className="d-flex flex-row gap-3 h-100">
										<div className="w-100 h-100">
											<Card
												className="bg-white"
												style={{
													height: "316px",
													borderRadius: "8px",
													overflow: "hidden",
												}}
											>
												<Card.Body className="h-100 w-100 d-flex flex-row justify-content-center align-items-center">
													<img
														style={{
															maxWidth: "100%",
															maxHeight: "100%",
															objectFit: "contain", // Mantém o aspecto proporcional da imagem
															borderRadius: "inherit", // Mantém o arredondamento de borda do card
														}}
														src={pedidoAtual?.FILE ? pedidoAtual?.FILE : imgPdv}
														alt=""
													/>
												</Card.Body>
											</Card>
										</div>

										<div
											style={{
												minHeight: "100%",
											}}
											className="w-100 h-100 d-flex flex-column gap-3"
										>
											{Object.keys(pedidoAtual).length > 0 && (
												<>
													<div className="d-flex flex-column gap-3">
														<Card
															style={{
																minHeight: "67px",
															}}
															className="py-1 px-3 d-flex flex-column gap-1"
														>
															<strong>Material</strong>
															<h5 className="fw-bold">
																{pedidoAtual?.MATERIAL_CODE} {pedidoAtual?.MATERIAL} - {pedidoAtual?.MATERIAL_COLOR}
															</h5>
														</Card>
													</div>

													<div className="d-flex flex-column gap-3">
														<Card
															style={{
																minHeight: "67px",
															}}
															className="py-1 px-3 d-flex flex-column gap-1"
														>
															<strong>Peso final do pedido</strong>
															<h5 className="fw-bold">
																{Number(
																	(pesoPolimento
																		? pesoPolimento
																		: pedidoAtual?.ACABAMENTO_FINAL_WEIGHT
																		? pedidoAtual?.ACABAMENTO_FINAL_WEIGHT
																		: pedidoAtual?.FINAL_WEIGHT) + pesoAdicional || 0
																).toFixed(3)}{" "}
																(g)
															</h5>

															{pedidoAtual?.POLIMENTO_WEIGHT &&
																JSON.parse(pedidoAtual.POLIMENTO_WEIGHT).map((item, index) => (
																	<div className="d-flex flex-row justify-content-between">
																		<span>{materialColor.find(cor => cor.id == item.material_color_id)?.name}</span>
																		<strong>{item.weight} (g)</strong>
																	</div>
																))}

															<div className="d-flex flex-row justify-content-between">
																<span>Peso adicional</span>
																<strong>{pesoAdicional} (g)</strong>
															</div>
														</Card>
													</div>
													<div className="d-flex flex-column gap-3">
														<Card
															style={{
																minHeight: "67px",
															}}
															className="py-1 px-3 d-flex flex-column gap-1"
														>
															<strong>Quebra</strong>
															<h5 className="fw-bold">{Number(calculoPedido?.total_break || 0).toFixed(3)} (g)</h5>
														</Card>
													</div>

													<div className="d-flex flex-column gap-3">
														<Card
															style={{
																minHeight: "67px",
															}}
															className="py-1 px-3 d-flex flex-column gap-1"
														>
															<strong>Total do pedido</strong>
															<h5 className="fw-bold">
																{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoPedido?.soma_todos_itens))}
															</h5>
														</Card>
													</div>
												</>
											)}
										</div>
									</div>
								</Card.Body>
							</Card>
						</div>

						<div
							className={tab == "detalhes" ? "d-block" : "d-none"}
							style={{
								maxHeight: "calc(100vh - 250px)",
								overflowX: "hidden",
								overflowY: "auto",
							}}
						>
							<Card className="mt-3 mb-3 ">
								<Card.Body>
									<h5>Valores adicionais</h5>
									<div className="d-flex flex-column justify-content-center mt-4 gap-3">
										{valoresAdicionais.valores?.map((item, index) => (
											<div key={index} className="d-flex flex-row justify-content-center align-items-center gap-3">
												<Button variant="secondary" className="m-0 py-1 h5 fw-bold">
													{index + 1}
												</Button>
												<InputGroup className="w-100">
													<Form.Control
														aria-label="nome"
														onBlur={e => {
															if (e.target.value) {
																setValoresAdicionais({
																	...valoresAdicionais,
																	valores: valoresAdicionais.valores?.map((v, i) => (v.id == item.id ? { ...v, nome: e.target.value } : v)),
																});
															}
														}}
														placeholder="Descrição"
													/>
													<InputGroup.Text>R$</InputGroup.Text>
													<Form.Control
														aria-label="valor"
														type="number"
														step={"0.01"}
														onBlur={e => {
															if (e.target.value != "") {
																setValoresAdicionais({
																	...valoresAdicionais,
																	valores: valoresAdicionais.valores?.map((v, i) => (v.id == item.id ? { ...v, valor: e.target.value } : v)),
																});
															}
														}}
														placeholder="Acrécimos"
													/>

													<Button
														onClick={e => setValoresAdicionais({ ...valoresAdicionais, valores: valoresAdicionais.valores?.filter(i => i.id != item.id) })}
														variant="danger"
													>
														<i className="ri-delete-bin-line"></i>
													</Button>
												</InputGroup>
											</div>
										))}
									</div>

									<div className="d-flex mt-3 flex-row justify-content-end">
										<Button
											className="d-flex flex-row gap-1 align-items-center justify-content-center"
											style={{
												fontSize: "16px",
											}}
											onClick={() =>
												setValoresAdicionais({
													...valoresAdicionais,
													valores: [
														...valoresAdicionais.valores,
														{
															id: valoresAdicionais.valores.length > 0 ? Number(valoresAdicionais.valores.slice(-1)[0].id) + 1 : 1,
															nome: "",
															valor: 0,
														},
													],
												})
											}
										>
											<i className="ri-add-line"></i>
										</Button>
									</div>
								</Card.Body>
							</Card>
							<Card className="mt-3 mb-3 ">
								<Card.Body>
									<Form.Group>
										<h5>Corrente</h5>
										<AsyncSelect
											id="corrente"
											loadOptions={loadOptions} // Use loadOptions para buscar as opções
											isMulti
											placeholder="Digite o nome do cliente, descrição ou nome do produto"
											isLoading={isCorrenteFetching}
											onChange={handleSelection}
											onInputChange={inputValue => {
												if (inputValue.length >= 3) {
													handleGetCorrentes(inputValue); // Chama para buscar novas opções
												}
											}}
											loadingMessage={() => "Procurando..."}
											noOptionsMessage={({ inputValue }) => (!inputValue ? "Digite para procurar" : "Nenhuma corrente encontrada")}
										/>
									</Form.Group>
									{corrente.corrente?.length > 0 && (
										<Card className="mt-3">
											<Card.Body>
												<Form.Label>Quantidade (un)</Form.Label>
												<div className="d-flex flex-column gap-2">
													{corrente?.corrente?.map(corr => (
														<div>
															<InputGroup>
																<InputGroup.Text>
																	{corr.nome} - {corr.descricao}
																</InputGroup.Text>
																<Form.Control onChange={e => handleChangeQuantidade(corr.value, e.target.value)} value={corr.quantidade} />
															</InputGroup>
														</div>
													))}
												</div>
											</Card.Body>
										</Card>
									)}
								</Card.Body>
							</Card>

							{pedidoAtual?.MATERIAL_COLOR_ID == 2 && (
								<Card className="mt-3 mb-3 ">
									<Card.Body>
										<h5>Valores de Ródio</h5>
										<div className="d-flex flex-column justify-content-center mt-4 gap-3">
											{valoresRodioAdicionais.valores?.map((item, index) => (
												<div key={index} className="d-flex flex-row justify-content-center align-items-center gap-3">
													<Button variant="secondary" className="m-0 py-1 h5 fw-bold">
														{index + 1}
													</Button>
													<InputGroup className="w-100">
														<Form.Control
															aria-label="nome"
															onBlur={e => {
																if (e.target.value) {
																	setValoresRodioAdicionais({
																		...valoresRodioAdicionais,
																		valores: valoresRodioAdicionais.valores?.map((v, i) =>
																			v.id == item.id ? { ...v, nome: e.target.value } : v
																		),
																	});
																}
															}}
															placeholder="Descrição"
														/>
														<InputGroup.Text>(g)</InputGroup.Text>
														<Form.Control
															aria-label="valor"
															type="number"
															step={"0.01"}
															onBlur={e => {
																if (e.target.value != "") {
																	setValoresRodioAdicionais({
																		...valoresRodioAdicionais,
																		valores: valoresRodioAdicionais.valores?.map((v, i) =>
																			v.id == item.id ? { ...v, valor: e.target.value } : v
																		),
																	});
																}
															}}
															placeholder="Peso"
														/>

														<Button
															onClick={e =>
																setValoresRodioAdicionais({
																	...valoresRodioAdicionais,
																	valores: valoresRodioAdicionais.valores?.filter(i => i.id != item.id),
																})
															}
															variant="danger"
														>
															<i className="ri-delete-bin-line"></i>
														</Button>
													</InputGroup>
												</div>
											))}
										</div>

										<div className="d-flex mt-3 flex-row justify-content-end">
											<Button
												className="d-flex flex-row gap-1 align-items-center justify-content-center"
												style={{
													fontSize: "16px",
												}}
												onClick={() =>
													setValoresRodioAdicionais({
														...valoresRodioAdicionais,
														valores: [
															...valoresRodioAdicionais.valores,
															{
																id: valoresRodioAdicionais.valores.length > 0 ? Number(valoresRodioAdicionais.valores.slice(-1)[0].id) + 1 : 1,
																nome: "",
																valor: 0,
															},
														],
													})
												}
											>
												<i className="ri-add-line"></i>
											</Button>
										</div>
										{/* <div className="d-flex flex-row justify-content-between my-3">
										<span>Valores de ródio do pedido: </span>
										<strong>
											{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
												Number(calculoPedido?.itens?.[0]?.valor_de_rodio_do_pedido_sem_adicional || 0)
											)}
										</strong>
									</div> */}

										<div className="d-flex flex-row justify-content-between">
											<span>Valores adicionais: </span>
											<strong>
												{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
													Number(calculoPedido?.itens?.[0]?.valor_adicional_de_rodio || 0)
												)}
											</strong>
										</div>
									</Card.Body>
								</Card>
							)}

							<Card className="mt-3 mb-3 ">
								<Card.Body>
									<Form.Group>
										<h5>Montagem</h5>
										<InputGroup>
											<Form.Select onChange={handleSelectTabela}>
												<option value="">Selecione a tabela</option>
												{listaTabelaMontagem?.map(item => (
													<option key={item.id} value={`${item.id}_${item.valor}`}>
														{item.montagem}
													</option>
												))}
											</Form.Select>
											{montagem.montagem.id_montagem != 3 ? (
												<Button
													style={{
														width: "150px",
													}}
												>
													{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(montagem?.montagem?.valor_montagem || 0))}
												</Button>
											) : (
												<Form.Control
													style={{
														width: "150px",
													}}
													onBlur={e => setMontagem({ ...montagem, montagem: { ...montagem.montagem, valor_montagem: e.target.value } })}
													step={"0.01"}
													type="number"
													placeholder="Digite o valor"
												></Form.Control>
											)}
										</InputGroup>
									</Form.Group>
								</Card.Body>
							</Card>

							{pedidoAtual.RESIN_TYPE == "1" && (
								<Card className="mt-3 mb-3 ">
									<Card.Body>
										<Form.Group>
											<h5>Borracha</h5>
											<InputGroup>
												{pedidoAtual.BILLING_TYPE != "4" ? (
													<Button
														style={{
															width: "150px",
														}}
													>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(pedidoAtual.BILLING_VALUE || 0))}
													</Button>
												) : (
													<Form.Control step={"0.01"} onChange={e => setPayload({ ...payload, valor_borracha: e.target.value })}></Form.Control>
												)}
											</InputGroup>
										</Form.Group>
									</Card.Body>
								</Card>
							)}

							<Card className=" mt-3 mb-3">
								<Card.Body className="d-flex flex-row align-items-end gap-3 justify-content-end">
									<Table borderless className="m-0">
										<tbody>
											{pedidoAtual?.ID_TYPE_ORDER != 2 && (
												<>
													<tr>
														<td>ML</td>
														<td className="text-end">
															<strong>{Number(calculoPedido?.soma_volume || 0).toFixed(3)} (ml)</strong>
														</td>
													</tr>

													<tr>
														<td>Valor da resina: </td>
														<td className="text-end">
															<strong>
																{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
																	Number(calculoPedido?.soma_valor_resina || 0)
																)}
															</strong>
														</td>
													</tr>
												</>
											)}

											<tr>
												{pedidoAtual?.POLIMENTO_WEIGHT &&
													JSON.parse(pedidoAtual.POLIMENTO_WEIGHT).map((item, index) => (
														<>
															<td>{materialColor.find(cor => cor.id == item.material_color_id)?.name}</td>
															<td className="text-end">
																<strong>{item.weight} (g)</strong>
															</td>
														</>
													))}
											</tr>

											<tr>
												<td>Peso adicional</td>
												<td className="text-end">
													<strong>{pesoAdicional} (g)</strong>
												</td>
											</tr>

											<tr>
												<td>Peso final</td>
												<td className="text-end">
													<strong>
														{Number(
															calculoPedido?.itens_materiais?.au_weight
																? calculoPedido?.itens_materiais?.au_weight
																: calculoPedido?.itens_materiais?.ag_weight || 0
														).toFixed(2)}{" "}
														(g)
													</strong>
												</td>
											</tr>

											<tr>
												<td>Quebra do pedido</td>
												<td className="text-end">
													<strong>{Number(calculoPedido?.total_break || 0).toFixed(3)} (g)</strong>
												</td>
											</tr>

											<tr>
												<td>Valor total de Ródio: </td>
												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoPedido?.total_rodio_value || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Valor total de Cravação: </td>
												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
															Number(calculoPedido?.itens?.[0]?.soma_valores_nailing || 0)
														)}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Valor do design: </td>
												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoPedido?.soma_design_values || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Valor da montagem: </td>
												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoPedido?.soma_montagem || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Adicionais: </td>
												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoPedido?.soma_adicionais || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Mão de obra: </td>
												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoPedido?.total_mo_value || 0))}
													</strong>
												</td>
											</tr>
											<tr>
												<td>Total: </td>
												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoPedido?.soma_todos_itens || 0))}
													</strong>
												</td>
											</tr>
										</tbody>
									</Table>
								</Card.Body>
							</Card>

							{/* <Card className=" p-3 shadow-sm">
								<Card.Body className="d-flex flex-column justify-content-between">
									<div className="d-flex flex-column">
										<Table borderless className="m-0">
											<tbody>
												<tr>
													<td>
														<span className="text-muted">Total de Material</span>
													</td>
													<td className="text-end">
														<strong>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(totalMaterialValue || 0)}</strong>
													</td>
												</tr>
												<tr>
													<td>
														<span className="text-muted">Total de Cravação</span>
													</td>
													<td className="text-end">
														<strong>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(totalNailing || 0)}</strong>
													</td>
												</tr>
												<tr>
													<td>
														<span className="text-muted">Total de Ródio</span>
													</td>
													<td className="text-end">
														<strong>
															{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(calculoNotas?.total_rodio_value || 0)}
														</strong>
													</td>
												</tr>
												<tr>
													<td>
														<span className="text-muted">Total de Mão de Obra</span>
													</td>
													<td className="text-end">
														<strong>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(totalMaoDeObraValue || 0)}</strong>
													</td>
												</tr>
												<tr>
													<td>
														<span className="text-muted">Total de Pedidos</span>
													</td>
													<td className="text-end">
														<strong>
															{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(calculoNotas?.soma_todos_itens || 0)}
														</strong>
													</td>
												</tr>

												<tr>
													<td>
														<span className="text-muted">Frete</span>
													</td>
													<td className="text-end">
														<strong>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(payload.total_frete || 0)}</strong>
													</td>
												</tr>

												<tr>
													<td>
														<span className="text-muted">Valores Adicionais</span>
													</td>
													<td className="text-end">
														<strong>
															{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
																parseFloat(valoresAdicionais?.valores?.reduce((acc, curr) => Number(acc) + Number(curr.valor), 0)) || 0
															)}
														</strong>
													</td>
												</tr>
											</tbody>
										</Table>
									</div>

									<hr className="my-3" />

									<div className="d-flex flex-row justify-content-between align-items-center">
										<div className="d-flex flex-column align-items-start">
											<h5 className="mb-0">Quebra</h5>
											<h6 className="fw-bold">{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(calculoPedido?.total_break || 0)}</h6>
										</div>

										<div className="d-flex flex-column align-items-start">
											<h5 className="mb-0">NFE</h5>
											<h6 className="fw-bold">{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(calculoPedido?.total_nfe || 0)}</h6>
										</div>

										<div className="d-flex flex-column align-items-start">
											<h3 className="mb-0">Total:</h3>
											<span className="display-6 fw-bold">
												{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
													(parseFloat(calculoPedido?.total) || 0) +
														(parseFloat(payload.total_frete) || 0) +
														(parseFloat(valoresAdicionais?.valores?.reduce((acc, curr) => Number(acc) + Number(curr.valor), 0)) || 0)
												)}
											</span>
										</div>
									</div>
								</Card.Body>
							</Card> */}
							<div>
								<Card className="border-0">
									<Card.Body className="d-flex flex-row justify-content-center">
										<Button
											style={{
												fontSize: "18px",
											}}
											className="d-flex flex-row align-items-center gap-2"
											disabled={Object.keys(pedidoAtual).length == 0}
											onClick={handleAddPedidoAtualEmPedidos}
										>
											<i className="ri-add-line"></i>
											Adicionar pedido
										</Button>
									</Card.Body>
								</Card>
							</div>
						</div>
					</div>
					<div
						className="border w-100 p-3 d-flex flex-column justify-content-between"
						style={{
							borderRight: "none",
							borderBottom: "none",
							borderTop: "none",
						}}
					>
						<Card className="border-0 w-100">
							<div className="mt-3 w-100" style={{ overflowX: "auto" }}>
								{pedidos.length > 0 ? (
									pedidos.map(pedido => (
										<Card key={pedido.CODE} className="mb-3">
											<Card.Body className="d-flex justify-content-between align-items-center">
												<div className="d-flex flex-row align-items-center gap-5 justify-content-between">
													<h5
														style={{
															minWidth: "180px",
														}}
														className="d-flex flex-column"
													>
														<span>Pedido</span> <strong>{pedido.CODE}</strong>
													</h5>
													<h5
														style={{
															minWidth: "230px",
														}}
														className=" d-flex flex-column"
													>
														<span>Peça:</span> <strong>{pedido.PART_CODE}</strong>
													</h5>
													<h5 className="mb-2 mb-md-0">{pedido.PRODUCT_SHORT_DESCRIPTION}</h5>
												</div>
												<Button variant="danger" onClick={() => removeOrder(pedido.CODE)}>
													<i className="ri-delete-bin-6-line"></i>
												</Button>
											</Card.Body>
										</Card>
									))
								) : (
									<Alert className="text-center w-100">Nenhum pedido adicionado</Alert>
								)}
							</div>
						</Card>

						<div className="d-flex flex-column gap-3">
							<Card className="">
								<Card.Body className="w-100 d-flex flex-row gap-2 justify-content-between">
									<Form.Group className="w-100">
										<h5>Frete</h5>
										<Form.Control
											value={payload.total_frete}
											type="text"
											onChange={e => setPayload({ ...payload, total_frete: e.target.value })}
											className="w-100"
											placeholder="Frete"
										></Form.Control>
									</Form.Group>
								</Card.Body>
							</Card>

							<Card className="d-flex flex-column">
								<Card.Body>
									<Table borderless className="m-0">
										<tbody>
											<tr>
												<td>Cravações </td>

												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoNotas?.soma_nailing_values || 0))}
													</strong>
												</td>
											</tr>
											<tr>
												<td>Resina </td>

												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoNotas?.soma_valor_resina || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Montagem </td>

												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoNotas?.soma_montagem || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Adicionais </td>

												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoNotas?.soma_adicionais || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Design </td>

												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoNotas?.soma_design_values || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Mão de obra </td>

												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoNotas?.total_mo_value || 0))}
													</strong>
												</td>
											</tr>
											<tr>
												<td>Total dos pedidos </td>

												<td className="text-end">
													<strong>
														{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(calculoNotas?.soma_todos_itens || 0))}
													</strong>
												</td>
											</tr>

											<tr>
												<td>Frete </td>

												<td className="text-end">
													<strong>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(Number(payload.total_frete || 0))}</strong>
												</td>
											</tr>
										</tbody>
									</Table>
								</Card.Body>
							</Card>
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<div className="w-100 d-flex flex-row justify-content-between">
						<div className="w-50 d-flex flex-row align-items-end gap-3">
							<Button onClick={handleCloseModal} className="w-20" variant="secondary">
								Cancelar
							</Button>
							<Button disabled={pedidos.length == 0} className="w-20" onClick={handleSave} variant="primary">
								Gerar Nota
							</Button>
						</div>
						<div className="d-flex flex-row gap-3 w-50 justify-content-end align-items-end">
							<div className="d-flex flex-row w-50 justify-content-end gap-4 align-items-end">
								{/* <div className="d-flex flex-column align-items-start">
									<h5 className="mb-0">Frete</h5>
									<h6 className="fw-bold">{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(payload.total_frete || 0)}</h6>
								</div> */}

								{/* <div className="d-flex flex-column align-items-start">
									<h5 className="mb-0">NFE</h5>
									<h6 className="fw-bold">{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(calculoNotas?.total_nfe || 0)}</h6>
								</div> */}

								<div className="d-flex flex-column align-items-start">
									<h3 className="mb-0">Total:</h3>
									<span className="display-6 fw-bold">
										{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
											(parseFloat(calculoNotas?.total) || 0) + (parseFloat(payload.total_frete) || 0) + (parseFloat(payload.total_acrecimos) || 0)
										)}
									</span>
								</div>
							</div>
						</div>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};
