import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import { useNota } from "../../../Context/NotaContext/NotaContext";
import { useEffect, useState } from "react";
import { toastErro, toastSucesso } from "../../Swal";
import { baseURLPDF } from "../../../constants/endpoints";
import Swal from "sweetalert2";
import { AlteraStatusNota } from "../AlterarStatus/AlteraStatus";
import { cnpjMask, cpfMask } from "../../../functions/Mask";
import { productServices } from "../../../apiServices/productServices";

export const NotaDetalhes = ({ nota }) => {
	const { getNotaDetalhes, notaDetalhes } = useNota();
	const [open, setOpen] = useState(false);
	const token = localStorage.getItem("token");

	const { geraPdfNota } = useNota();
	const [materialColor, setMaterialColor] = useState([]);

	const handleGetNotaDetalhes = async () => {
		getNotaDetalhes(nota.note_id);

		const responseMaterialColor = await productServices.selectListMaterialcolor("", token);
		setMaterialColor(responseMaterialColor.data);
		setOpen(true);
	};

	const handleCloseModal = () => setOpen(false);

	const formatCurrency = value => {
		return new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
		}).format(Number(value));
	};

	const totalRodioValue = notaDetalhes?.orders_nota?.reduce((acc, curr) => acc + Number(curr.rodio_value), 0);
	// const totalFundicaoValue = notaDetalhes?.orders_nota?.reduce((acc, curr) => acc + Number(curr.fundicao_value), 0);
	const totalMaoDeObraValue = notaDetalhes?.orders_nota?.reduce((acc, curr) => acc + Number(curr.mo_total_value), 0);
	const totalOrderValue = notaDetalhes?.orders_nota?.reduce((acc, curr) => acc + Number(curr.total_order_value), 0);
	const [totalNailingValues, setTotalNailingValues] = useState(0);

	const calculateNailingValues = () => {
		const array = notaDetalhes?.orders_nota?.map(n => n.nailing_values);

		let flattenedArray = [];
		if (array) {
			// Parse each JSON string in the array
			const parsedArray = array.map(item => JSON.parse(item));

			// Flatten the array of objects
			flattenedArray = parsedArray.flat();
		}

		const arrayValores = flattenedArray?.map(item => {
			const array = Object.values(item);
			return array.reduce((acc, curr) => acc + Number(curr), 0);
		});

		const totalValores = arrayValores.reduce((acc, curr) => acc + curr, 0);
		setTotalNailingValues(totalValores);
	};

	const totalAuValue = notaDetalhes?.orders_nota?.reduce((acc, curr) => acc + Number(curr.material_value), 0);

	useEffect(() => {
		calculateNailingValues();
	}, [notaDetalhes]);

	const handleGeraPdfNota = async () => {
		try {
			const response = await geraPdfNota(nota.note_id);
			let element = document.createElement("a");
			element.target = "_blank";
			element.href = baseURLPDF + response.data.file_name;
			element.click();
			toastSucesso("PDF Gerado com sucesso!");
		} catch (error) {
			toastErro("Erro ao gerar PDF!");
		}
	};

	const handleChangeStatus = async () => {
		const result = await Swal.fire({
			title: "Selecione uma opção",
			input: "select",
			inputOptions: {
				Fruits: {
					apples: "Apples",
					bananas: "Bananas",
					grapes: "Grapes",
					oranges: "Oranges",
				},
				Vegetables: {
					potato: "Potato",
					broccoli: "Broccoli",
					carrot: "Carrot",
				},
				icecream: "Ice cream",
			},
			inputPlaceholder: "Selecione uma opção",
			showCancelButton: true,
			inputValidator: value => {
				if (!value) {
					return "Você deve selecionar uma opção!";
				}
			},
		});

		if (result.isConfirmed) {
			Swal.fire(`Você selecionou: ${result.value}`);
		}
	};

	const total = Number(notaDetalhes?.nota?.total || 0) + Number(notaDetalhes?.nota?.total_frete || 0) + Number(notaDetalhes?.nota?.total_acrecimos || 0);

	return (
		<>
			<Button onClick={handleGetNotaDetalhes}>Ver Detalhes</Button>
			<Modal backdrop={false} fullscreen show={open} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Resumo da Nota</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex flex-row justify-content-between">
						<h3>
							<strong>Nota Nº {nota?.note_id}</strong>
						</h3>

						<h3>
							<strong> {notaDetalhes?.nota?.client_fantasy_name ? notaDetalhes?.nota?.client_fantasy_name : notaDetalhes?.nota?.client_name} </strong>
							<h4 className="fw-bold text-muted">{notaDetalhes?.nota?.document?.lenght > 11 ? cnpjMask(notaDetalhes?.nota?.document) : cpfMask(notaDetalhes?.nota?.document)}</h4>
						</h3>
						<h3 className="d-flex flex-column">
							<span>
								<strong>Data:</strong> {new Date(nota?.created_at).toLocaleDateString("pt-BR")}
							</span>
							<span>
								<strong>Hora:</strong> {new Date(nota?.created_at).toLocaleTimeString("pt-BR")}
							</span>
						</h3>
					</div>
					<hr />
					<Table bordered>
						<thead>
							<tr className="text-end">
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Imagem
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Pedido
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Peso de Metal (g)
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Quebra
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Peso Corrente (g)
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Adicionais
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Montagem
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Valor Mão de Obra
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Valor Ródio
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Valores de Cravação
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Valor do design
								</th>
								<th
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									scope="col"
								>
									Valor Total do Pedido
								</th>
							</tr>
						</thead>
						<tbody>
							{notaDetalhes?.orders_nota?.map((item, index) => (
								<tr className="text-end" key={index}>
									<td className="text-center">
										<img src={item.file} width={50} alt="" />
									</td>
									<td>{item.order_code}</td>
									<td>
										{item.obj_peso &&
											JSON.parse(item.obj_peso).map((item, index) => (
												<div className="d-flex flex-row justify-content-between">
													<strong>
														{materialColor
															?.find(cor => cor.id == item.material_color_id)
															?.name?.replace(" c/ Ródio", "")
															.replace(" - Ouro Amarelo", "")
															.replace(" - Ouro Branco", "")
															.replace(" - Ouro Rosé", "")}
													</strong>
													<span>{item.weight} (g)</span>
												</div>
											))}
										<hr className="my-0 mt-1" />
										<div className="d-flex flex-row justify-content-between">
											<strong>Total: </strong>
											{/* Hifen separador */}
											<span>{Number(item?.metal_weight_total).toFixed(2)} (g)</span>
										</div>
									</td>
									<td>{item.quebra_pedido}</td>
									<td>{item.peso_corrente}</td>
									<td>{formatCurrency(item.adicionais)}</td>
									<td>{formatCurrency(item.montagem)}</td>
									<td>{formatCurrency(item.mo_total_value)}</td>
									<td>{formatCurrency(item.rodio_value)}</td>
									<td>{formatCurrency(item.soma_valores_nailing)}</td>
									<td>{formatCurrency(item.design_value)}</td>
									<td className="table-secondary text-end border-1">{formatCurrency(item.total_order_value)}</td>
								</tr>
							))}
							{/* Linha de total */}
							<tr className="text-end">
								<td colSpan={5}></td>
								<td colSpan={1}>{formatCurrency(notaDetalhes?.nota?.soma_adicionais)}</td>
								<td>{formatCurrency(notaDetalhes?.nota?.soma_montagem)}</td>
								<td>{formatCurrency(totalMaoDeObraValue)}</td>
								{/* <td>{formatCurrency(totalFundicaoValue)}</td> */}
								<td>{formatCurrency(totalRodioValue)}</td>
								<td>{formatCurrency(notaDetalhes?.nota?.soma_nailing_values)}</td>
								<td>{formatCurrency(notaDetalhes?.nota?.soma_design_values)}</td>
								<td>
									<strong>{formatCurrency(totalOrderValue)}</strong>
								</td>
							</tr>
							<tr className="text-end">
								<td
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									colSpan={4}
								>
									<strong>Total</strong>
								</td>

								<td
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									colSpan={4}
								>
									<div className="d-flex flex-row justify-content-between">
										<strong>Frete: </strong>
										<span>{formatCurrency(notaDetalhes?.nota?.total_frete)}</span>
									</div>
								</td>
								<td
									style={{
										backgroundColor: "#506FD9",
										color: "white",
									}}
									colSpan={4}
								>
									<div className="d-flex flex-row justify-content-between">
										<strong>Total: </strong>
										<strong>{formatCurrency(total)}</strong>
									</div>
								</td>
							</tr>
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer className="border-0">
					<Button onClick={handleGeraPdfNota} variant="primary">
						Gerar PDF
					</Button>
					<div>
						<AlteraStatusNota nota={nota} />
					</div>
				</Modal.Footer>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleCloseModal}>
						Fechar
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
